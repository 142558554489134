import { Icon } from "@blueprintjs/core"
import { HeadlessService } from "@novu/headless"
import {
  IMessage,
  INotificationCenterStyles,
  NovuProvider,
  PopoverNotificationCenter,
} from "@novu/notification-center"
import React from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

import styles from "./Notifications.module.css"

import { NotificationsQuery } from "./__generated__/NotificationsQuery.graphql"

import { withRelayPageContainerNoLoading } from "~/RelayPageContainer"

type Props = {
  subscriberId: string | null
  appIdentifier: string | null
  backendUrl: string | null
  email: string | null
  accountId: number | null
}

type State =
  | {
      tag: "loading"
    }
  | {
      tag: "not-available"
    }
  | {
      tag: "not-enabled"
    }
  | {
      tag: "enabled"
    }

const QUERY = graphql`
  query NotificationsQuery($email: String, $accountId: Int) {
    person: people(
      where: { email: { _eq: $email }, account_id: { _eq: $accountId } }
    ) {
      id
      email
    }
  }
`

const Notifications = (props: Props) => {
  const { subscriberId, appIdentifier, backendUrl, accountId, email } = props

  const data = useLazyLoadQuery<NotificationsQuery>(QUERY, {
    email: email,
    accountId: accountId,
  })

  const userHasAssociatedPerson = data.person.length > 0

  const [state, setState] = React.useState<State>({ tag: "loading" })

  React.useEffect(() => {
    if (
      !subscriberId ||
      !appIdentifier ||
      !userHasAssociatedPerson ||
      !backendUrl
    ) {
      setState({ tag: "not-available" })
    } else {
      const headlessService = new HeadlessService({
        applicationIdentifier: appIdentifier,
        subscriberId,
        backendUrl,
      })

      headlessService.initializeSession({
        listener: () => void 0,
        onSuccess: () => {
          headlessService.fetchUserPreferences({
            onSuccess: (settings) => {
              const isInAppChannelEnabled = settings.some(
                (setting) => setting.preference.channels.in_app,
              )

              if (!isInAppChannelEnabled) {
                setState({ tag: "not-enabled" })
              } else {
                setState({ tag: "enabled" })
              }
            },
            listener: () => void 0,
            onError: (error) => {
              console.error(
                "Failed to load notifications user preferences",
                error,
              )

              // Render the bell anyway
              setState({ tag: "enabled" })
            },
          })
        },
        onError: (error) => {
          console.error("Failed to initialize session", error)
          setState({ tag: "not-available" })
        },
      })
    }
  }, [subscriberId, appIdentifier, userHasAssociatedPerson, backendUrl])

  function handleOnNotificationClick(message: IMessage) {
    if (message?.payload?.url) {
      window.location.href = message.payload.url as string
    }
  }

  const customStyles = {
    loader: {
      root: {
        stroke: "var(--runn-blue)",
      },
    },
    header: {
      root: {
        ".mantine-Badge-root": {
          background: " linear-gradient(90deg, #4057df 0%, #a200ff 100%)",
        },
      },
      cog: { display: "none" },
    },
    notifications: {
      root: {
        ".nc-notifications-list-item": {
          "::before": {
            background: " linear-gradient(90deg, #4057df 0%, #a200ff 100%)",
          },
        },
        ".nc-notifications-list-item-unread": {
          ".nc-bell-button-dot rect": {
            fill: "#4057df !important",
          },
        },
        ".nc-notifications-list-item-read": {
          p: {
            color: "var(--smoke) !important",
          },
        },
      },
      listItem: {
        dotsButton: {
          path: {
            fill: "black",
          },
        },
        unread: {
          "::before": { background: "black" },
        },
      },
    },
  } as INotificationCenterStyles

  if (state.tag === "loading") {
    return null
  }

  if (state.tag === "not-available") {
    console.warn(
      "src/containers/Notifications.tsx component is missing subscriberId, appIdentifier or backendUrl or has no person associated with the user. Skipping rendering.",
    )

    return null
  }

  if (state.tag === "not-enabled") {
    return null
  }

  return (
    <NovuProvider
      subscriberId={subscriberId}
      applicationIdentifier={appIdentifier}
      backendUrl={backendUrl}
      styles={customStyles}
    >
      <PopoverNotificationCenter
        colorScheme={"light"}
        onNotificationClick={handleOnNotificationClick}
        showUserPreferences={false}
        offset={4}
      >
        {({ unseenCount }) => (
          <div className={styles.bell}>
            <Icon icon="notifications" size={20} color="var(--winter)" />
            {unseenCount > 0 && <div className={styles.unseenCountDot} />}
          </div>
        )}
      </PopoverNotificationCenter>
    </NovuProvider>
  )
}

export default withRelayPageContainerNoLoading(Notifications)
